import { userStore } from '@context/userStore';
import DetailList from '@elements/DetailList';
import Icon from '@elements/Icon';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import { classNames } from '@helpers/classNames';
import { occupations } from '@helpers/occupations';
import { getCategories } from '@providers/category';
import { getProfileData } from '@providers/profile';
import PasswordReset from 'feature/profile/fragment/PasswordReset';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import PersonalDetailsModalForm from './PersonalDetailsModalForm';

const phoneUtil = PhoneNumberUtil.getInstance();

export default function PersonalDetailsForm({ isOnboarding }) {
  const { profileId } = userStore();
  const [workfields, setWorkfields] = useState({
    label: 'Werkveld*',
    choices: [{ value: '- Selecteren -' }],
  });
  const [showModal, setShowModal] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [occupation, setOccupation] = useState(occupations.choices[0].value);
  const [workfield, setWorkfield] = useState(workfields.choices[0].value);
  const [phoneValue, setPhoneValue] = useState('');
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let response = await getCategories();
      let categoryChoices = [];
      if (response && response.status === 200) {
        categoryChoices = [
          { value: '- Selecteren -' },
          ...response.data.map((item) => ({ value: item.name })),
        ];
      }
      setWorkfields((prevWorkfields) => ({
        ...prevWorkfields,
        choices: categoryChoices,
      }));
      // Fetch user data
      response = await getProfileData();
      if (response && response.status === 200) {
        setProfileData(response.data);
        // Match and set occupation
        let matchingOccupation = null;
        for (const choice of occupations.choices) {
          if (choice.value === response.data.occupation) {
            matchingOccupation = choice;
            break;
          }
        }
        setOccupation(matchingOccupation?.value || occupations.choices[0].value);
        // Match and set work field
        let matchingWorkfield = null;
        for (const choice of categoryChoices) {
          if (choice.value === response.data.work_field) {
            matchingWorkfield = choice;
            break;
          }
        }
        setWorkfield(matchingWorkfield?.value || '- Selecteren -');
        // Set phone field
        setPhoneValue(response.data.phone_number);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [profileId]);

  let formattedProfileNumber;

  function formatToNational(phoneNum, countryCode) {
    const parsedNumber = phoneUtil.parse(phoneNum, countryCode);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
  }

  if (profileData && profileData.phone_number) {
    const databaseNumberValue = profileData.phone_number;
    formattedProfileNumber = formatToNational(databaseNumberValue, 'NL');
  } else {
    formattedProfileNumber = phoneValue;
  }

  const userDetails = [
    { label: 'E-mail', value: profileData?.email },
    { label: 'Voornaam', value: profileData?.firstname },
    { label: 'Tussenvoegsel', value: profileData?.lastname_prefix },
    { label: 'Achternaam', value: profileData?.lastname },
    { label: 'Telefoonnummer', value: formattedProfileNumber },
    { label: 'Beroep', value: profileData?.occupation },
    { label: 'Werkveld', value: profileData?.work_field },
  ];

  const onClickModal = () => {
    setShowModal(!showModal);
  };

  const modalFormProps = {
    occupations,
    occupation,
    setOccupation,
    workfield,
    setWorkfield,
    workfields,
    profileData,
    setProfileData,
    setShowModal,
    phoneValue,
    setPhoneValue,
    setUpdated,
    setError,
  };

  useEffect(() => {
    if (updated) {
      toast.success('Gegevens bijgewerkt.', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setUpdated(false);
    }
    if (error) {
      toast.error('Kan de gegevens niet bijwerken', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
      setError(false);
    }
  }, [updated, error]);

  return (
    <div className="container mx-auto pb-10 font-primary">
      <div className="flex justify-between">
        <div className="flex flex-col">
          {!isOnboarding && <h2 className="font-primary text-3xl">Persoonlijke gegevens</h2>}
          <p className={classNames('mb-8 mt-1 ', isOnboarding && 'font-light')}>
            Bekijk hier je profielgegevens.
          </p>
        </div>

        <div className="mt-1">
          <Button
            type="button"
            icon="pencil"
            buttonType="secondary"
            onClick={() => onClickModal()}
          />
        </div>
      </div>
      {!isLoading ? (
        <DetailList details={userDetails} />
      ) : (
        <div>
          {[...Array(1)].map((_, index) => (
            <div key={index}>
              <Skeleton className="h-[400px]" />
            </div>
          ))}
        </div>
      )}

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Werk mijn details bij"
        modalContent={<PersonalDetailsModalForm {...modalFormProps} />}
      />
      <div className="pt-10">
        <PasswordReset />
      </div>
    </div>
  );
}

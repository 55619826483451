import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import ListBox from '@elements/forms/ListBox';
import PhoneInputField from '@elements/forms/PhoneNumberInput';
import { handleValidation } from '@helpers/handleValidation';
import { formatPhoneNumber, getRegionForPhoneNumber } from '@helpers/phoneValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { getProfileData, updateProfile } from '@providers/profile';
import Spinner from 'components/admin/Spinner';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validationSchema } from './validation';

export default function PersonalDetailsModalForm({
  occupations,
  occupation,
  setOccupation,
  workfield,
  setWorkfield,
  workfields,
  profileData,
  setProfileData,
  setShowModal,
  phoneValue,
  setPhoneValue,
  handleCancel,
  setUpdated,
  setError,
}) {
  const [isValid, setIsValid] = useState({});
  const [occupationValid, setOccupationValid] = useState(true);
  const [workfieldValid, setWorkfieldValid] = useState(true);
  const [phoneCountry, setPhoneCountry] = useState('NL');
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  function handleOccupationChange(choice) {
    setOccupation(choice.value);
    customOccupationValidation(choice.value);
  }

  function handleWorkfieldChange(choice) {
    setWorkfield(choice.value);
    customWorkfieldValidation(choice.value);
  }

  function handlePhoneChange(value) {
    if (value.length > 5) {
      setPhoneValue(value);
      setPhoneCountry(getRegionForPhoneNumber(value));
      setValue('phoneNumber', value);

      trigger('phoneNumber').then((result) => {
        setIsValid((prevState) => ({
          ...prevState,
          phoneNumber: result,
        }));
      });
    } else {
      setPhoneValue(value);
      setValue('phoneNumber', value);
    }
  }

  function customOccupationValidation(value) {
    if (value === occupations.choices[0].value) {
      setOccupationValid(false);
      setIsValid({ ...isValid, occupation: false });
      return false;
    } else {
      setOccupationValid(true);
      setIsValid({ ...isValid, occupation: true });
      return true;
    }
  }

  function customWorkfieldValidation(value) {
    if (value === workfields.choices[0].value) {
      setWorkfieldValid(false);
      setIsValid({ ...isValid, workfield: false });
      return false;
    } else {
      setWorkfieldValid(true);
      setIsValid({ ...isValid, workfield: true });
      return true;
    }
  }

  async function onSubmit(formData) {
    if (!customOccupationValidation(occupation)) {
      return;
    }
    if (!customWorkfieldValidation(workfield)) {
      return;
    }

    if (!profileData) {
      return;
    }

    let updatedPhoneValue = formatPhoneNumber(phoneValue);
    if (updatedPhoneValue.length <= 5 || updatedPhoneValue == false) {
      updatedPhoneValue = '';
      setPhoneValue(null);
    }

    trigger('phoneNumber').then((result) => {
      setIsValid((prevState) => ({
        ...prevState,
        phoneNumber: result,
      }));
    });

    const updatedProfile = {
      email: formData.email.toLowerCase(),
      firstname: formData.firstname,
      lastname_prefix: formData.lastnamePrefix,
      lastname: formData.lastname,
      phone_number: updatedPhoneValue,
      occupation: occupation,
      work_field: workfield,
    };

    setLoading(true);
    let response = await updateProfile(updatedProfile);
    if (response && response.status === 200) {
      response = await getProfileData();
      if (response && response.status === 200) {
        setProfileData(response.data);
      }
      setUpdated(true);
      setShowModal(false);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (profileData) {
      setValue('email', profileData.email);
      setValue('firstname', profileData.firstname);
      setValue('lastnamePrefix', profileData.lastname_prefix);
      setValue('lastname', profileData.lastname);
    }
  }, [profileData]);

  return (
    <form className="flex flex-col gap-4 pt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6">
        <InputField
          id="email"
          label="email*"
          name="email"
          type="text"
          register={register}
          errors={errors}
          message={errors.email?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.email);
          }}
          isValid={isValid['email']}
          defaultValue={profileData?.email || ''}
        />
        <InputField
          id="firstname"
          label="Voornaam*"
          name="firstname"
          type="text"
          register={register}
          errors={errors}
          message={errors.firstname?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.firstname);
          }}
          isValid={isValid['firstname']}
          defaultValue={profileData?.firstname || ''}
        />
        <div className="flex w-full gap-4">
          <div className="sm:w-1/3">
            <InputField
              id="lastnamePrefix"
              label="Tussenvoegsel"
              name="lastnamePrefix"
              type="text"
              register={register}
              errors={errors}
              message={errors.lastnamePrefix?.message}
              onBlur={(e) => {
                handleValidationWrapper(e, errors?.lastnamePrefix);
              }}
              isValid={isValid['lastnamePrefix']}
              defaultValue={profileData?.lastname_prefix || ''}
            />
          </div>
          <div className="w-full sm:w-2/3">
            <InputField
              id="lastname"
              label="Achternaam*"
              name="lastname"
              type="text"
              register={register}
              errors={errors}
              message={errors.lastname?.message}
              onBlur={(e) => {
                handleValidationWrapper(e, errors?.lastname);
              }}
              isValid={isValid['lastname']}
              defaultValue={profileData?.lastname || ''}
            />
          </div>
        </div>
        <div className="z-30 flex flex-col gap-2">
          <ListBox
            choices={occupations.choices}
            label={occupations.label}
            onChange={handleOccupationChange}
            errors={!occupationValid}
            isValid={isValid['occupation']}
            value={occupation}
          />
          {!occupationValid && (
            <div className="font-primary text-sm font-light tracking-wide text-functional-error">
              Beroep is verplicht
            </div>
          )}
        </div>
        <div className="z-20 flex flex-col gap-2">
          <ListBox
            choices={workfields.choices}
            label={workfields.label}
            onChange={handleWorkfieldChange}
            errors={!workfieldValid}
            isValid={isValid['workfield']}
            value={workfield}
          />
          {!workfieldValid && (
            <div className="font-primary text-sm font-light tracking-wide text-functional-error">
              Werkveld is verplicht
            </div>
          )}
        </div>
        <PhoneInputField
          id="phoneNumber"
          label="Telefoonnummer"
          name="phoneNumber"
          register={register}
          errors={errors}
          message={errors.phoneNumber?.message}
          isValid={isValid['phoneNumber']}
          phoneInputProps={{
            country: phoneCountry,
            value: formatPhoneNumber(phoneValue),
            onChange: handlePhoneChange,
          }}
        />
      </div>
      <div className="flex justify-end gap-4">
        {loading ? (
          <Spinner spinnerSize="size-10" text="Verzoek aan het verwerken" textSize="text-sm" />
        ) : (
          <>
            <Button
              label="Annuleren"
              type="button"
              buttonType="secondary"
              onClick={handleCancel ? handleCancel : () => setShowModal(false)}
            />
            <Button label="Versturen" type="submit" buttonType="primary" />
          </>
        )}
      </div>
    </form>
  );
}

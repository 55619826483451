export const occupations = {
  label: 'Beroep*',
  choices: [
    { value: '- Selecteren -' },
    { value: 'Aannemer' },
    { value: 'Adviseur' },
    { value: 'Architect' },
    { value: 'Bouwkundige' },
    { value: 'Docent' },
    { value: 'EP-adviseur' },
    { value: 'Facilitair manager' },
    { value: 'Gebouwbeheerder' },
    { value: 'Inkoper' },
    { value: 'Installateur (E)' },
    { value: 'Installateur (S)' },
    { value: 'Installateur (W)' },
    { value: 'Monteur' },
    { value: 'Projectmanager/Kwaliteitsborger' },
    { value: 'Projectontwikkelaar' },
    { value: 'Student' },
    { value: 'Technisch tekenaar' },
    { value: 'Vastgoedprofessional' },
    { value: 'Werkvoorbereider' },
    { value: 'Overig' },
  ],
};

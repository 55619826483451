import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import Address from '@elements/forms/Address';
import AddressLookupResult from '@elements/forms/Address/AddressLookupResult';
import { classNames } from '@helpers/classNames';
import { getProfileAddresses, updateAddress } from '@providers/profile';
import Spinner from 'components/admin/Spinner';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

export default function AddressUpdateForm({ isOnboarding }) {
  const { profileId } = userStore();
  const [address, setAddress] = useState(null);
  const [billingFormData, setBillingFormData] = useState('');
  const [shippingFormData, setShippingFormData] = useState('');
  const [shippingAddress, setShippingAddress] = useState(null);
  const [showBillingAddressModal, setShowBillingAddressModal] = useState(false);
  const [showShippingAddressModal, setShowShippingAddressModal] = useState(false);
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [isValid, setIsValid] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAddressData = async () => {
    setIsLoading(true);
    const response = await getProfileAddresses();
    if (response && response.status === 200) {
      const billingData = response.data.find((address) => address.type === 'BILLING_ADDRESS');
      const shippingData = response.data.find((address) => address.type === 'SHIPPING_ADDRESS');
      if (billingData) {
        setBillingFormData(billingData.mail_lines);
        setAddress(billingData);
      }
      if (shippingData) {
        setShippingFormData(shippingData.mail_lines);
        setShippingAddress(shippingData);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateAddress = async (addressType, address) => {
    const payload = {
      type: address.type,
      city: address.city,
      country: address.country,
      street: address.street,
      postcode: address.postcode,
      number: address.number,
      number_suffix: address?.numberSuffix || address?.number_suffix,
      mail_lines: address?.mailLines || address?.mail_lines,
      is_po_box: address?.isPoBox || address?.is_po_box || false,
      profile_id: profileId,
    };

    setLoading(true);
    let response = await updateAddress(profileId, payload);
    if (response && response.status === 200) {
      setUpdated(true);
      addressType === 'billing'
        ? setShowBillingAddressModal(false)
        : setShowShippingAddressModal(false);
      await getAddressData();
    } else {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAddressData();
    };
    fetchData();
  }, [profileId]);

  const onClickBillingAddressModal = () => {
    setShowBillingAddressModal(!showBillingAddressModal);
  };

  const onClickShippingAddressModal = () => {
    setShowShippingAddressModal(!showShippingAddressModal);
  };

  const isValidBillingAddress =
    address && address?.postcode && address?.number && address?.city ? true : false;

  useEffect(() => {
    if (updated) {
      toast.success('Gegevens bijgewerkt.', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setUpdated(false);
    }
    if (error) {
      toast.error('Kan de gegevens niet bijwerken', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
      setError(false);
    }
  }, [updated, error]);

  return (
    <div className="container mx-auto pb-10 font-primary">
      <div className="flex justify-between">
        <div className="flex flex-col text-primary-dark">
          {!isOnboarding && <h2 className="font-primary text-3xl">Privé adressen</h2>}
          <p className={classNames('mb-8 mt-1 ', isOnboarding && 'font-light')}>
            Bekijk hier je privé adresgegevens van je persoonlijke profiel.
          </p>
        </div>
      </div>
      <div className="rounded-md bg-white px-8 pb-12 pt-5 shadow-lg">
        {isLoading ? (
          <div>
            {[...Array(1)].map((_, index) => (
              <div key={index} className="my-8">
                <Skeleton className="h-[100px]" />
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between pb-4 pt-2">
              <h2 className="font-primary text-xl">Factuuradres</h2>
            </div>
            <div className="flex gap-8">
              {billingFormData ? (
                <div className="w-full max-w-lg divide-y divide-slate-200 rounded-lg bg-white shadow-lg">
                  <AddressLookupResult
                    country={address.country}
                    mailLines={billingFormData}
                    isBillingAddress={true}
                  />
                </div>
              ) : (
                <p className="pt-2 font-light">Geen adresgegevens</p>
              )}

              <div className="mt-0.5">
                <Button
                  type="button"
                  icon={billingFormData ? 'pencil' : 'plus'}
                  buttonType="secondary"
                  onClick={onClickBillingAddressModal}
                />
              </div>
            </div>
          </>
        )}
        <Modal
          showModal={showBillingAddressModal}
          setShowModal={setShowBillingAddressModal}
          modalTitle="Wijzig mijn adres"
          modalContent={
            <div>
              <Address
                isBillingAddress={true}
                address={address}
                setAddress={setAddress}
                isValid={isValid}
                setIsValid={setIsValid}
                setAddressNotFound={setAddressNotFound}
              />
              <div className="flex justify-end gap-4 pt-4">
                {loading ? (
                  <Spinner
                    spinnerSize="size-10"
                    text="Verzoek aan het verwerken"
                    textSize="text-sm"
                  />
                ) : (
                  <>
                    {' '}
                    <Button
                      label="Annuleren"
                      type="button"
                      buttonType="secondary"
                      onClick={() => setShowBillingAddressModal(false)}
                    />
                    <Button
                      label="Versturen"
                      type="button"
                      buttonType="primary"
                      onClick={async () => await handleUpdateAddress('billing', address)}
                      disabled={addressNotFound || !isValidBillingAddress}
                    />
                  </>
                )}
              </div>
            </div>
          }
        />
        {isLoading ? (
          <div>
            {[...Array(1)].map((_, index) => (
              <div key={index}>
                <Skeleton className="h-[100px]" />
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between pb-3 pt-12">
              <h2 className="font-primary text-xl">Bezorgadres</h2>
            </div>
            <div className="flex items-center gap-8">
              {shippingFormData ? (
                <div className="w-full max-w-lg divide-y divide-slate-200 rounded-lg bg-white shadow-lg">
                  <AddressLookupResult
                    country={shippingAddress.country}
                    mailLines={shippingFormData}
                    isBillingAddress={false}
                  />
                </div>
              ) : (
                <p className="font-light">Geen adresgegevens</p>
              )}
              <div className="mt-0.5 flex items-center gap-4">
                <Button
                  type="button"
                  icon={shippingFormData ? 'pencil' : 'plus'}
                  buttonType="secondary"
                  onClick={onClickShippingAddressModal}
                  disabled={!isValidBillingAddress}
                />
                {!isValidBillingAddress && (
                  <p className="text-sm font-light">Factuuradres is vereist</p>
                )}
              </div>
            </div>
          </>
        )}
        <Modal
          showModal={showShippingAddressModal}
          setShowModal={setShowShippingAddressModal}
          modalTitle="Wijzig mijn bezorgadres"
          modalContent={
            <>
              <Address
                isBillingAddress={false}
                address={shippingAddress}
                setAddress={setShippingAddress}
                isValid={isValid}
                setIsValid={setIsValid}
                setAddressNotFound={setAddressNotFound}
              />
              <div className="flex justify-end gap-4 pt-4">
                {loading ? (
                  <Spinner
                    spinnerSize="size-10"
                    text="Verzoek aan het verwerken"
                    textSize="text-sm"
                  />
                ) : (
                  <>
                    <Button
                      label="Annuleren"
                      type="button"
                      buttonType="secondary"
                      onClick={() => setShowShippingAddressModal(false)}
                    />
                    <Button
                      label="Versturen"
                      type="button"
                      buttonType="primary"
                      onClick={async () => await handleUpdateAddress('shipping', shippingAddress)}
                      disabled={addressNotFound}
                    />
                  </>
                )}
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}
